import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Heading = styled.h3`
  font-weight: bold;
`;

const Select = styled.select`
  height: 4rem;
  width: 25%;
  margin-top: 0.5rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  background-color: white;
  font-size: 1.5rem;
`;

const StyledSpan = styled.span`
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  font-weight: 300;
`;

const WidgetBuilderTimeFrameSelect = ({ configurations, heading, setConfigurations }) => {
  const startYear = new Date().getFullYear() - 1;
  const yearsList = [
    'current',
    ...Array.from({ length: startYear - 1950 + 1 }, (_, i) => startYear - i)
  ];

  const defaultStartYear = configurations.graduationTimeframeStart || yearsList[0];
  const defaultEndYear = configurations.graduationTimeframeEnd || startYear;
  const startYearsList =
    defaultEndYear === 'current'
      ? yearsList
      : yearsList.filter(year => year !== 'current' && year <= defaultEndYear);

  const [firstYear, setFirstYear] = useState(defaultStartYear);
  const [secondYear, setSecondYear] = useState(defaultEndYear);
  const [filteredYearsList, setFilteredYearsList] = useState(
    yearsList.filter(year => year >= defaultStartYear)
  );

  const handleStartYearChange = selectedValue => {
    setFirstYear(selectedValue);
    setFilteredYearsList([
      'current',
      ...yearsList.filter(year => year >= selectedValue && year !== 'current')
    ]);
    setConfigurations(prev => ({
      ...prev,
      graduationTimeframeStart: selectedValue
    }));
  };

  const handleEndYearChange = selectedValue => {
    setSecondYear(selectedValue);
    setConfigurations(prev => ({
      ...prev,
      graduationTimeframeEnd: selectedValue
    }));
  };

  return (
    <Fragment>
      <Heading>{heading}</Heading>

      <Select
        aria-label="select graduation start year"
        data-cy="graduation-timeframe-first-year-select"
        value={firstYear}
        onChange={({ target: { value } }) =>
          handleStartYearChange(value === 'current' ? 'current' : parseInt(value))
        }
      >
        {startYearsList.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Select>
      <StyledSpan>to</StyledSpan>
      <Select
        aria-label="select graduation end year"
        data-cy="graduation-timeframe-second-year-select"
        value={secondYear}
        onChange={({ target: { value } }) =>
          handleEndYearChange(value === 'current' ? 'current' : parseInt(value))
        }
      >
        {filteredYearsList.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Select>
    </Fragment>
  );
};

WidgetBuilderTimeFrameSelect.propTypes = {
  configurations: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  setConfigurations: PropTypes.func.isRequired
};

export default WidgetBuilderTimeFrameSelect;
