import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../../../styles';
import Pencil from '../../../images/pencil.svg';
import {
  AlumniWidgetBuilderSkillsModal,
  TextButton,
  Toggle,
  WidgetBuilderHeadingToggle
} from 'components';

const { mediumDarkGray, sapphireD20 } = color;

const RadioRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

const Radio = styled(Toggle)`
  margin-top: 1;
`;

const EditIcon = styled(Pencil)`
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  margin-right: 0.75rem;
`;

const ModalButton = styled(TextButton)`
  width: auto;
  margin-top: 0.7rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  margin-top: 0.5rem;
  border: 1px solid ${mediumDarkGray};
  border-radius: 3px;
`;

const AlumniWidgetBuilderOccupationsAndSkills = ({
  configurations,
  setConfigurations,
  skillsData
}) => {
  const { accordionType } = configurations;
  const [localOccupationsHeading, setLocalOccupationsHeading] = useState(
    'Employed in these occupations'
  );
  const [localSkillsHeading, setLocalSkillsHeading] = useState('Have these in-demand skills');

  const [isSkillsModalOpen, setIsSkillsModalOpen] = useState(false);

  useEffect(() => {
    const debouncedUpdateParentState = setTimeout(() => {
      const localHeading =
        accordionType === 'occupations' ? localOccupationsHeading : localSkillsHeading;
      setConfigurations({ ...configurations, accordionHeadingText: localHeading });
    }, 400);
    return () => clearTimeout(debouncedUpdateParentState);
  }, [accordionType, localOccupationsHeading, localSkillsHeading]);

  return (
    <Fragment>
      <WidgetBuilderHeadingToggle
        configurations={configurations}
        setConfigurations={setConfigurations}
        headingText="Skills and Occupations"
        configurationKey="hasAccordion"
        description="Select the data to be displayed in your widget"
      />
      <RadioRow>
        <Radio
          key={`option`}
          onChange={() => {
            setConfigurations({
              ...configurations,
              accordionType: 'skills'
            });
          }}
          dataCy="skills-radio"
          label="Skills"
          type="radio"
          da
          checked={accordionType === 'skills'}
          color="black"
        />
        {accordionType === 'skills' && (
          <ModalButton color={sapphireD20} onClick={() => setIsSkillsModalOpen(true)}>
            <EditIcon data-cy="edit-skills-button" />
            Edit Skills
          </ModalButton>
        )}
      </RadioRow>
      {accordionType === 'skills' && (
        <label>
          Edit Skills Heading Text
          <TextArea
            id="accordion-header-text"
            rows="4"
            cols="50"
            data-cy="skills-heading-textarea"
            value={localSkillsHeading}
            aria-label="edit widget heading text"
            onChange={event => {
              setLocalSkillsHeading(event.target.value);
            }}
          />
        </label>
      )}
      <RadioRow>
        <Radio
          key={`option`}
          onChange={() => {
            setConfigurations({
              ...configurations,
              accordionType: 'occupations'
            });
          }}
          dataCy="occupations-radio"
          label="Occupations"
          type="radio"
          checked={accordionType === 'occupations'}
          color="black"
        />
        {accordionType === 'occupations' && (
          <ModalButton color={sapphireD20}>
            <EditIcon />
            Edit Occupations
          </ModalButton>
        )}
      </RadioRow>
      {accordionType === 'occupations' && (
        <label>
          Edit Occupations Heading Text
          <TextArea
            id="accordion-header-text"
            rows="4"
            cols="50"
            data-cy="occupations-heading-textarea"
            value={localOccupationsHeading}
            aria-label="edit widget heading text"
            onChange={event => {
              setLocalOccupationsHeading(event.target.value);
            }}
          />
        </label>
      )}

      {isSkillsModalOpen && (
        <AlumniWidgetBuilderSkillsModal
          isOpen={isSkillsModalOpen}
          configurations={configurations}
          setConfigurations={setConfigurations}
          skillsData={skillsData}
          toggleIsModalOpen={() => setIsSkillsModalOpen(!isSkillsModalOpen)}
        />
      )}
    </Fragment>
  );
};

AlumniWidgetBuilderOccupationsAndSkills.propTypes = {
  configurations: PropTypes.object.isRequired,
  setConfigurations: PropTypes.func.isRequired,
  skillsData: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, id: PropTypes.string }))
};

export default AlumniWidgetBuilderOccupationsAndSkills;
