import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CheckboxModal } from 'components';

const AlumniWidgetBuilderSkillsModal = ({
  configurations,
  isOpen,
  setConfigurations,
  skillsData,
  toggleIsModalOpen
}) => {
  const [selectedOptions, setSelectedOptions] = useState(configurations.selectedSkills);

  useEffect(() => {
    if (
      configurations.selectedSkills &&
      configurations.selectedSkills.length === 0 &&
      skillsData.length > 0
    ) {
      setSelectedOptions(skillsData.slice(0, 6).map(({ id }) => id));
    }
  }, [skillsData]);

  return (
    <CheckboxModal
      directionsText="Select up to 6 skills to display in your widget"
      headingText="Select Skills"
      isOpen={isOpen}
      options={skillsData}
      selectionLimit={6}
      selectedOptions={selectedOptions}
      setSelectedOptions={selectedOptions => {
        setConfigurations({
          ...configurations,
          selectedSkills: selectedOptions
        });
      }}
      toggleIsModalOpen={toggleIsModalOpen}
    />
  );
};

AlumniWidgetBuilderSkillsModal.propTypes = {
  configurations: PropTypes.object.isRequired,
  setConfigurations: PropTypes.func.isRequired,
  skillsData: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, id: PropTypes.string }))
};

export default AlumniWidgetBuilderSkillsModal;
