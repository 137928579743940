import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Label = styled.label`
  font-weight: ${({ hasBoldLabel }) => (hasBoldLabel ? '600' : '500')};
`;

const Select = styled.select`
  height: 4rem;
  width: 100%;
  margin-top: 0.5rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  background-color: white;
  font-size: 1.5rem;
`;

const WidgetBuilderSelect = ({
  configurations,
  hasBoldLabel = false,
  id,
  label,
  optionsList,
  selectionLabel,
  setConfigurations
}) => {
  const { [selectionLabel]: selectedValue } = configurations;

  const handleSelectionChange = newOptionName => {
    const { value } = optionsList.find(({ value }) => value === newOptionName);

    setConfigurations({
      ...configurations,
      [selectionLabel]: value
    });
  };

  return (
    <Label hasBoldLabel={hasBoldLabel}>
      {label}
      <Select
        id={id}
        data-cy={`${id}-select`}
        value={selectedValue}
        onChange={({ target: { value } }) => handleSelectionChange(value)}
      >
        {optionsList.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </Label>
  );
};

WidgetBuilderSelect.propTypes = {
  configurations: PropTypes.object.isRequired,
  hasBoldLabel: PropTypes.bool,
  label: PropTypes.string.isRequired,
  optionsList: PropTypes.array.isRequired,
  selectionLabel: PropTypes.string.isRequired,
  setConfigurations: PropTypes.func.isRequired
};

export default WidgetBuilderSelect;
